.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.mobile-mode{
  display: none;
}

.pc-mode{
  display: block;
}

.top-menu {
  width: 80%;
}
.top-search {
  width: 20%;
}

.show-menu-button{
  cursor: pointer;
}

.mobile-menu{
  width: 100%;
  position: absolute;
  top: 60px;
  z-index: 2;
}

.left-sider{
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 767.99px) {
  .App-header{
    padding-left: 24px;
    padding-right: 24px;
  }

  .mobile-mode{
    display: inherit;
  }
  
  .pc-mode{
    display: none;
  }

  .top-menu {
    width: 20%;
  }
  .top-search {
    width: 0;
    flex: 1;
  }
  .left-sider{
    display: none;
  }
}